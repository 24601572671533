<template>
  <div class="admin-royalties">
    <!-- Alert when import being processed -->
    <import-being-processed v-if="processing" />

    <advanced-data-table
      action="outboundPayments/get"
      :headers="headers"
      ref="table"
      :limit="50"
      :filters.sync="filters"
    >
      <template #cell(calculated_amount)="{item}">
        {{ formatCurrency(item.calculated_amount, item.currency.code) }}
      </template>

      <template #cell(final_amount)="{item}">
        {{ formatCurrency(item.final_amount, item.currency.code) }}
      </template>

      <template #cell(adjustment_amount)="{item}">
        {{ formatCurrency(item.adjustment_amount, item.currency.code) }}
      </template>

      <template #cell(commission)="{item}">
        {{ formatCurrency(item.commission, item.currency.code) }}
      </template>

      <template #cell(total_payment)="{item}">
        {{
          formatCurrency(
            item.calculated_amount + item.adjustment_amount - item.commission,
            item.currency.code
          )
        }}
      </template>

      <template #cell(status)="{item}">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
        </h5>
      </template>

      <template #cell(client)="{item}">
        {{ item.user.name }}
      </template>

      <template #cell(book)="{item}">
        <router-link
          target="_blank"
          :to="{ name: 'AdminEditBook', params: { id: item.book.id } }"
        >
          {{ item.book.title }}
        </router-link>
      </template>

      <template #cell(created_at)="{item}">
        {{ formatDate(item.created_at) }}
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <ebp-button
          outlined
          variant="success"
          size="sm"
          class="ml-1"
          :to="{
            name: 'ViewRoyalties',
            query: { outbound_payment_id: item.id }
          }"
          v-tooltip.top-center="$t('view-royalties')"
        >
          <i class="bx bxs-show"></i>
        </ebp-button>
        <ebp-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          :loading="removing === item.id"
          @click="remove(item)"
          v-tooltip.top-center="$t('rollback')"
        >
          <i class="bx bx-undo"></i>
        </ebp-button>
      </template>

      <!-- Filters -->
      <template #filters>
        <!-- User filter -->
        <adt-filter :label="$t('client')">
          <item-search
            action="users/search"
            label="name"
            trackBy="id"
            v-model="filters.user_id"
          />
        </adt-filter>
      </template>
    </advanced-data-table>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import AdtFilter from "@/components/core/adt-filter";
import royaltySchema from "@/validation-schemas/sale-import";
import ItemSearch from "@/components/core/item-search";
import ImportBeingProcessed from "@/components/import-being-processed";
import royaltyStatuses from "@/fixtures/royalty-statuses";

import truncate from "@/helpers/truncate";
import { mapState } from "vuex";
import { find } from "lodash-es";

export default {
  name: "admin-royalties",
  components: {
    AdvancedDataTable,
    ItemSearch,
    AdtFilter,
    ImportBeingProcessed
  },
  data() {
    return {
      royaltyStatuses: royaltyStatuses.map(s => ({
        text: this.$t(s),
        value: s
      })),
      headers: [
        { key: "calculated_amount", sortable: true },
        { key: "final_amount", sortable: true },
        { key: "adjustment_amount", sortable: true },
        { key: "commission", sortable: true },
        { key: "total_payment", label: "total-payment-2", sortable: true },
        "status",
        "client",
        { key: "royalties_count", label: "royalties" },
        { key: "created_at", label: "date-added", sortable: true }
      ],
      editedItem: null,
      saving: false,
      open: false,
      showAddSaleImport: false,
      error: null,
      addTabs: ["import"],
      removing: false,
      filters: {
        user_id: null,
        book_id: null,
        sales_channel_id: null,
        status: null,
        inbound_payment_id: null
      },
      processing: false
    };
  },
  mounted() {
    const channel = this.$pusher.subscribe("import-processed");
    channel.bind("import-processed", ({ royalty }) => {
      this.$router.push({
        name: "ViewSaleRecords",
        query: {
          sales_import_id: royalty.id
        }
      });
    });

    this.$watch(
      () => {
        return this.$refs.table.tableData;
      },
      items => {
        this.processing = !!items.filter(i => i.status === "processing").length;
      }
    );
  },
  destroyed() {
    this.$pusher.unsubscribe("import-processed");
  },
  computed: {
    ...mapState("royalties", ["processorTypes"])
  },
  methods: {
    getProcessorName(code) {
      const type = find(this.processorTypes, o => o.code === code);
      return type ? type.name : "";
    },
    truncate,
    added() {
      this.showAddSaleImport = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async remove(royalty) {
      if (
        confirm(
          this.$t("remove-payment-message", {
            name: royalty.user.name
          })
        )
      ) {
        this.removing = royalty.id;
        try {
          await this.$store.dispatch("outboundPayments/delete", royalty.id);
          this.$refs.table.change("id", royalty.id, {
            ...royalty,
            status: "rolled_back"
          });
        } catch (err) {
          console.error(err);
        }
        this.removing = false;
      }
    },
    edit(royalty) {
      this.editedItem = royalty;
      this.open = true;
    },
    async save() {
      this.error = null;
      const royalty = this.editedItem;
      const validation = royaltySchema.validate(royalty);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("royalties/update", royalty);
          this.$refs.table.change("id", royalty.id, royalty);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
